.App .navbar {
  padding: 2em;
  background-color: whitesmoke;
  color:black;
  display: flex;

  /* box-shadow: 0 1em 1em rgba(60, 60, 60, .4);  */
  position: sticky;
  top: 0;
  box-shadow: 0px 5px 5px rgba(28, 28, 28, .1);
  z-index: 2;

  display: flex;
  justify-content: space-between;
  
}

.App {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: 0;
  /* height: max-content;
  overflow-y: scroll;
  overflow-x: hidden; */
}
.App::-webkit-scrollbar {
  display: none;
  
}

::-webkit-scrollbar-track {
  opacity: 0;
  
  background-color: lightgray;
}

::-webkit-scrollbar-thumb {
  background: rgb(68, 13, 84);
}
body {
  background-color: whitesmoke;
  width: 100vw;
  overflow: hidden;
  font-family: "Poppins";
 
}

.App {
  transition: all 1s;
 
}

.App .navbar {

  font-size:18px;
}

.App .navbar nav {
 
  display: flex;
  
  right: 1em;
}
.App .navbar a {
  padding: 0 1em;
  color: black;
  text-decoration: none;
  transition: all 500ms;
  z-index: 3;
}

.App .navbar a:hover {
  color: rgb(96, 13, 96);
}



.App main {
 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
 
  color: black;
  background-color: whitesmoke;
  height: 60vh;
  text-align: center;
  font-family: "Poppins";

 
}

.App main .fade {
  position: relative;

}
.App main img {
  border-radius: 50%;
  width: 100px;
  border: 3px solid rgb(68, 13, 84);
  position: absolute;
  left: calc((50vw - 100px)/2);
  /* right: 50px; */
  top: -80px;
  margin: 0;
}

.App main div {
  box-shadow: 1px 1px 20px rgba(28, 28, 28, .1);
  width: 50%;
  border-radius: 2em;
  padding: 1em;
}

.App main h1 {
  color: rgb(96, 13, 96);
 
}

.App main hr {
  width: 50%;
  animation-name: grow;
  animation-duration: 2s;
}

.fade {
  animation-name: fade;
  animation-duration: 2s;
}

.grow {
  animation-name: grow;
  animation-duration: 2s;
}

.slide {
  animation-name: slide;
  animation-duration: 2s;
  animation-direction: normal;
}

@keyframes grow {
  from { width: 0};
}

@keyframes fade {
  from {opacity: 0;}

}

@keyframes slide {
  /* from {right: 90vw; margin-right: 20px;}
  to {right: 0vw}; */
  from {opacity: 0.4;}
}

/* @keyframes slide-reverse {
  from {right: auto; opacity: 1;}
  to {right: 200vw; opacity: 0};
} */

.page2 {
  background-color: rgb(238,238,238);
  /* background-color: rgb(28,28,28); */
 text-align: center;
  color: black;
  transition: all 1s;
  width: 100%;
  padding:  0;
  display: grid;
  justify-content: center;
  height: 100vh;
  overflow-y: scroll;
 
  scrollbar-width: 5px;
  padding-bottom: 2em;

}

.page2 h2 {
  position: sticky;
  top:0;
}
.page2 .project-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  height: 100vh;
}

.page2 .project-container.transtion {
  justify-content:left;
}

.page2 .project {
  position: sticky;
  width: 90%;
  height: 100%;
  min-width: 90vw;

 color:whitesmoke;
 text-align: left;
  box-shadow: 1px 1px 20px  rgba(20, 20, 20, .6);
  background-color: whitesmoke;
  color:black;
  padding: 20px;
  border-radius: 20px;
 
}

.page2::-webkit-scrollbar {
  width: 10px;
}
/* .page2 .project.prev.slide {
  animation-name: slide;
  animation-duration: 2s;
  animation-direction: normal;
} */


.page2 h2 {
  text-align: center;
  text-transform: capitalize;
}

.page2 h3 {
  text-transform: uppercase;
  color:rgb(68, 13, 84);
  /* text-decoration: 2px underline solid white; */
  font-weight: bolder;
}

.page2 .content {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
}

.page2 .content img {
  max-width: 100%;
  
  right: 20px;
 
  /* border: 5px solid rgb(68, 13, 84); */
  border-top: 5px solid rgb(68, 13, 84);
  border-radius: 5px;
  max-height: 50vh;
}

.page2 .content p {
  max-width: 100%;
  overflow-y: scroll;
}

.page2 .content a {
  color: rgb(68, 13, 84);
 
  width: 200px;

  text-align: center;
}

.page2  button {
  border: none;
  background-color: lightgray;
  border-radius: 100%;
  height: 10px;
  width: 10px;
  padding: 0;
  opacity: .8;
  color: rgb(28,28,28);
 transition: all 1s;
  cursor: pointer;
  margin: 0 10px;
}

.page2 .project.slide {
  display: inline;
}

.page2 button:disabled {
  opacity: .8;
  cursor: not-allowed;
}
.page2 button:hover {
  
  height:11px;
  width:11px;
  background-color: gray;
  
}
.page2 button.active {
  /* background-color: whitesmoke; */
  background-color: rgb(96, 13, 96);
  opacity: 1;
  height: 12px;
  width: 12px;
}

@media only screen and (max-width: 768px) {
    .App main {
      padding: 0;
    }
   
    .App main div {
      width: 100%;
      margin-top: 5em;
      padding: 0;
    }
    .App main div img {
      width: 50px;
      height:50px;
      top: -30px;
    }

    .App .navbar {
      font-size: 8px;
    }
    

    .page2 .content {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }
}

footer {
 
  
}

.page2 header {
  position: sticky;
 
  z-index: 1;
  background-color: inherit;
  padding: 2em;
}


